// *********************************************************************************** //
// * Loading Screen Animation                                                        * //
// *********************************************************************************** //
// Intricate loading screen, shows an animation on page load
// Is this too much voodoo for our holy purposes?
// https://github.com/Zemts/loading-screens/tree/master/randomly%20moving

function LoadScreen() {
  let background;
  let container;
  let text;
  let bottomLeft;
  let bottomRight;
  let topLeft;
  let topRight;
  let cubesSet;
  let durationTime;
  let intervalID;
  let translate;
  const STYLES = {
    BG: {
      'background-color': 'rgb(0, 191, 255)',
      display: 'block',
      'font-size': '0',
      height: '100%',
      left: '0',
      position: 'fixed',
      'text-align': 'center',
      top: '0',
      width: '100%',
      'z-index': '1000',
    },
    CONTAINER: {
      'background-color': 'transparent',
      display: 'inline-block',
      height: `${window.navigator.userAgent.toLowerCase().includes('mobi') ? '12rem' : '15vmin'}`,
      position: 'relative',
      top: '40vh',
      transform: 'translate(0,0)',
      'transition-duration': '0.3s',
      'transition-property': 'transform',
      'transition-timing-function': 'ease-in-out',
      width: `${window.navigator.userAgent.toLowerCase().includes('mobi') ? '12rem' : '15vmin'}`,
      // width: `${(window.screen.availWidth / 100) * parseFloat(getComputedStyle(document.documentElement).fontSize)}px`,
    },
    CUBES: {
      height: '7vmin',
      position: 'absolute',
      transform: 'translate(0,0)',
      'transition-duration': '0.3s',
      'transition-property': 'transform',
      'transition-timing-function': 'ease-in-out',
      width: '7vmin',
    },
    BOTTOM: {
      bottom: 0,
    },
    LEFT: {
      left: 0,
    },
    RIGHT: {
      right: 0,
    },
    TOP: {
      top: 0,
    },
    TEXT: {
      color: 'white',
      display: 'block',
      'font-family': 'Roboto, sans-serif',
      'font-size': '7vmin',
      'font-weight': '800',
      height: '8vmin',
      left: '39%',
      'line-height': '8vmin',
      margin: '0 0 0 -21.5vmin',
      position: 'relative',
      'text-align': 'center',
      top: `${window.navigator.userAgent.toLowerCase().includes('mobi') ? '50vh' : '45vh'}`,
      transform: 'translate(0,0)',
      'transition-duration': '0.3s',
      'transition-property': 'transform',
      'transition-timing-function': 'ease-in-out',
      width: '66vmin',
      'letter-spacing': '7px',
    },
    // since this runs before CSS is loaded, this simulates the class .mdc-typography--headline5
    HEADLINE5: {
      color: 'white',
      display: 'block',
      'padding-top': '10px',
      'font-family': 'Roboto, sans-serif',
      'font-size': '1.5rem',
      'font-weight': '400',
      'line-height': '2rem',
      'text-decoration': 'inherit',
      'text-transform': 'inherit',
      'letter-spacing': 'normal',
    },
  };

  function stop() {
    document.body.removeChild(background);
    clearInterval(intervalID);
    intervalID = null;
  }

  function getSafeSpaceDistance(oneTimeOffset, restrictiveElement) {
    const hLimit = parseFloat(getComputedStyle(restrictiveElement).width) / 2; // half of vertical side
    const vLimit = parseFloat(getComputedStyle(restrictiveElement).height) / 2; // half of horizontal side
    const hDiff = hLimit - (7 * oneTimeOffset); // because the text wider than the container
    const vDiff = vLimit - (4 * oneTimeOffset); // because the text is placed below the cube
    return {
      x: (hDiff < 0) ? 0 : hDiff,
      y: (vDiff < 0) ? 0 : vDiff,
    };
  }

  function getCorrectiveOffset(safeDistance, position) {
    const correction = {
      left: 0,
      top: 0,
    };

    if (Math.abs(position.x) > safeDistance.x) {
      correction.left = -1 * position.x;
    }
    if (Math.abs(position.y) > safeDistance.y) {
      correction.top = -1 * position.y;
    }

    return (correction.left || correction.top) ? correction : false;
  }

  function getMovingParameters(direction) {
    const cubeSetIndex = Math.floor(Math.random());
    const oneTimeOffset = parseFloat(getComputedStyle(topLeft).width);
    const safeSpace = getSafeSpaceDistance(oneTimeOffset, background);
    const correction = getCorrectiveOffset(safeSpace, translate);

    if (correction) { // protection against "out of sight escaping"
      let cube = bottomRight;
      if (correction.left < 0 && correction.top < 0) {
        cube = topLeft;
      } else if (correction.left < 0 && correction.top >= 0) {
        cube = bottomLeft;
      } else if (correction.left >= 0 && correction.top < 0) {
        cube = topRight;
      }

      return {
        cube,
        left: correction.left,
        top: correction.top,
      };
    }

    const ways = ['bottom', 'left', 'right', 'top'];
    // the order of elements in "signedOffsets", "positions", "distances" depend on the order of elements in "ways"
    const signedOffsets = [oneTimeOffset, -1 * oneTimeOffset, oneTimeOffset, -1 * oneTimeOffset];
    const positions = [translate.y, translate.x, translate.x, translate.y];
    const distances = [safeSpace.y, safeSpace.x, safeSpace.x, safeSpace.y];

    const variants = ways.map((item, index) => {
      if (positions[index] + signedOffsets[index] < distances[index]) {
        return {
          cube: cubesSet[item][cubeSetIndex],
          left: (item === 'left' || item === 'right') ? signedOffsets[index] : 0,
          top: (item === 'bottom' || item === 'top') ? signedOffsets[index] : 0,
        };
      }
      return false;
    });

    const available = variants.filter((item) => item);
    const availableIndex = Math.floor(Math.random() * available.length);
    const desirable = ways.indexOf(direction);

    if (available.length === 0 || variants[desirable] === false) {
      console.error('The set of cubes cannot move. List of available directions: ', available);
      return {
        cube: topLeft,
        left: 0,
        top: 0,
      };
    }

    return (desirable !== -1) ? variants[desirable] : available[availableIndex];
  }

  function setTranslate(element, offset) {
    element.style.setProperty('transform', `translate(${offset.x}px, ${offset.y}px)`);
  }

  function applyMovingParameters(params) {
    translate.x += params.left;
    translate.y += params.top;

    if (params.top > 0) { // if we move down, the text should be first
      setTranslate(text, translate);
    }

    setTranslate(params.cube, { x: params.left, y: params.top });
    setTimeout(() => {
      if (params.top <= 0) {
        setTranslate(text, translate);
      }
      setTranslate(container, translate);
      setTranslate(params.cube, { x: 0, y: 0 });
    }, durationTime);
  }

  function move(direction) {
    const parameters = getMovingParameters(direction);
    applyMovingParameters(parameters);
  }

  function start() {
    if (intervalID) {
      stop();
    }
    // document.body is used because sizes of elements and their offsets are defined in 'vmin' units
    document.body.appendChild(background);
    move();
    intervalID = setInterval(move, durationTime * 2 + 100);
  }

  function setStyles(element, styles) {
    const computedStyles = (Array.isArray(styles)) ? styles : [styles];
    for (let a = 0; a < computedStyles.length; a += 1) {
      Object.keys(computedStyles[a]).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(computedStyles[a], key)) {
          element.style.setProperty(key, computedStyles[a][key]);
        }
      });
    }
  }

  function initialize() {
    translate = {
      x: 0,
      y: 0,
    };

    background = document.createElement('div');
    container = document.createElement('div');
    text = document.createElement('div');

    // Cavalry - Neutral
    bottomLeft = document.createElement('div');
    bottomLeft.innerHTML = '<svg height="91" width="91" preserveAspectRatio="xMidYMid" viewBox="25 50 150 100" ><g class="outline"><path stroke="black" stroke-width="4" d="M45,45 l110,0 0,110 -110,0 z" fill="rgb(170, 255, 170)"></path></g><g class="decorator"><path d="M45,155L155,45" fill="none" stroke="black" stroke-width="4"></path></g></svg>';

    // Cavalry - Pending
    bottomRight = document.createElement('div');
    bottomRight.innerHTML = '<svg height="91" width="91" preserveAspectRatio="xMidYMid" viewBox="25 50 150 100" ><g class="outline"><path stroke="black" stroke-width="4" d="M63,63 C63,20 137,20 137,63 C180,63 180,137 137,137 C137,180 63,180 63,137 C20,137 20,63 63,63 Z" fill="rgb(255,255,128)"></path><path d="M63,63 C63,20 137,20 137,63 C180,63 180,137 137,137 C137,180 63,180 63,137 C20,137 20,63 63,63 Z" fill="none" stroke="rgb(239, 239, 239)" stroke-width="5" stroke-dasharray="4,4"></path></g><g class="decorator"><path d="M50,135L150,65" fill="none" stroke="black" stroke-width="4"></path></g></svg>';

    // Cavalry - Hostile
    topLeft = document.createElement('div');
    topLeft.innerHTML = '<svg height="91" width="91" preserveAspectRatio="xMidYMid" viewBox="25 50 150 100" ><g class="outline"><path stroke="black" stroke-width="4" d="M 100,28 L172,100 100,172 28,100 100,28 Z" fill="rgb(255,128,128)"></path></g><g class="decorator"><path d="M65, 137 L137, 65 Z" fill="none" stroke="black" stroke-width="4"></path></g></svg>';

    // Cavalry - Friendly
    topRight = document.createElement('div');
    topRight.innerHTML = '<svg height="91" width="91" preserveAspectRatio="xMidYMid" viewBox="25 50 150 100" ><g class="outline"><path stroke="black" stroke-width="4" d="M25,50 l150,0 0,100 -150,0 z" fill="rgb(128,224,255)"></path></g><g class="decorator"><path d="M25,150L175,50" fill="none" stroke="black" stroke-width="4"></path></g></svg>';

    // This array contains several example symbols that we are loading
    // The idea is to simulate a loading effect on the loading screen where each symbol is briefly mentioned on the loading screen
    // Instead of writing all these symbols out or importing the objects and looping over each one, I am creating a simulated list of symbols that are 'loading'
    const exampleSymbols = [
      'Default Land Unit',
      'Infantry',
      'Checkpoint',
      'Mechanized Infantry',
      'Target Reference Point',
      'Armored Track Unit',
      'Artillery',
      'Cavalry',
      'Engineers',
      'AP Mines',
      'Wire Obstacle (Single String Concertina)',
      'AT GUN',
      'AT Gun Heavy',
      'AT GUN Light',
      'AT GUN Medium',
      'CBRN',
      'UAV (equipment)',
      'Attack by Fire',
      'Mass Demonstration',
      'Explosion',
      'Class I Supply Point',
      'Key Terrain',
      'Medical',
      'Tented Camp',
      'Special Troops',
    ];

    // This is the total count of symbols from militarySymbolsObject, mod1Object, mod2Object and commandPostObject
    const totalSymbolCount = 55;
    const symbolCounter = totalSymbolCount - exampleSymbols.length;

    // Iterate over all the elements in the exampleSymbols array and push them to the exampleSymbols array
    for (let i = 0; i < symbolCounter; i += 1) {
      // The Modulus in "[i % exampleSymbols.length]" will repeat elements in the exampleSymbols array
      // The end result will have the exampleSymbols array with 715 entries with each value repeating itself
      exampleSymbols.push(exampleSymbols[i % exampleSymbols.length]);
    }

    // This is the loading screen text. It will iterate over the number of symbols we have and each symbol name
    // Totally unnecessary as the app does not need this IOT load, but a loading screen may or may not be good UX practice
    for (let i = 0; i <= totalSymbolCount; i += 1) {
      // eslint-disable-next-line no-loop-func
      setTimeout((ix) => {
        text.innerHTML = `Loading Assets ${ix}/${totalSymbolCount}`;
        // Since this is running before the CSS loads, manually add the CSS to each span element. STYLES.HEADLINE5 mimics MDC Typography
        text.insertAdjacentHTML('beforeend', `<span style="${Object.entries(STYLES.HEADLINE5).map((property) => `${property[0]}: ${property[1]};`).join('')}">${exampleSymbols[ix]}</span>`);
        if (ix >= totalSymbolCount) {
          text.classList.toggle('zoomSymbolOutLeftOnMobile');
          setTimeout(() => {
            // When the loop is finished, change the loading text to 'Loaded...' and animate the ellipses
            text.classList.toggle('zoomSymbolOutLeftOnMobile');
            text.classList.toggle('bounceIn');
            text.classList.toggle('loading-ellipses');
            text.innerHTML = 'Loaded';
          }, 300);
        }
        // Each element in the loop will display for 25ms
      }, 25 * i, i);
    }

    setStyles(background, STYLES.BG);
    setStyles(container, STYLES.CONTAINER);
    setStyles(bottomLeft, [STYLES.CUBES, STYLES.BOTTOM, STYLES.LEFT]);
    setStyles(bottomRight, [STYLES.CUBES, STYLES.BOTTOM, STYLES.RIGHT]);
    setStyles(topLeft, [STYLES.CUBES, STYLES.LEFT, STYLES.TOP]);
    setStyles(topRight, [STYLES.CUBES, STYLES.RIGHT, STYLES.TOP]);
    setStyles(text, STYLES.TEXT);
    container.appendChild(bottomLeft);
    container.appendChild(bottomRight);
    container.appendChild(topLeft);
    container.appendChild(topRight);
    background.appendChild(container);
    background.appendChild(text);
    // getComputedStyle(topLeft) will not work because 'topLeft' was not added to document
    // if you want to use "getComputedStyle", move calculation of "durationTime" in "startMoving" function
    durationTime = parseFloat(topLeft.style.transitionDuration) * 1000;
    cubesSet = {
      bottom: [bottomLeft, bottomRight],
      left: [bottomLeft, topLeft],
      right: [bottomRight, topRight],
      top: [topLeft, topRight],
    };

    return {
      htmlNode: background,
      move,
      start,
      stop,
    };
  }

  return initialize();
}

// *********************************************************************************** //
// * Add Loading Screen Animation                                                    * //
// *********************************************************************************** //
const loading = new LoadScreen();

if (document.readyState === 'loading') {
  // Start the load screen before everything else
  loading.start();
  // This just removes that retarded query string parameter that Facebook always appends to your URL
  const param = 'fbclid';
  if (window.location.search.indexOf(`${param}=`) !== -1) {
    let replace = '';
    try {
      const url = new URL(window.location);
      url.searchParams.delete(param);
      replace = url.href;
    } catch (ex) {
      const regExp = new RegExp(`[?&]${param}=.*$`);
      replace = window.location.search.replace(regExp, '');
      replace = window.location.pathname + replace + window.location.hash;
    }
    window.history.replaceState(null, '', replace);
  }
}


// *********************************************************************************** //
// * Remove Loading Screen Animation                                                 * //
// *********************************************************************************** //
document.addEventListener('readystatechange', () => {
  try {
    if (document.readyState === 'complete') {
      console.log('%c MGRS-Mapper.com by CPT James Pistell... Scouts Out! ', 'background: #222; color: #bada55; font-size: 22px;');
      loading.stop();
      if (window.navigator.userAgent.toLowerCase().includes('mobi')) {
        // Adjust the logo width and viewBox on mobile devices
        const svgLogo = document.querySelector('.logoSection > svg');
        svgLogo.setAttributeNS(null, 'width', 200);
        svgLogo.setAttributeNS(null, 'viewBox', `${svgLogo.getBBox().x} ${svgLogo.getBBox().y} ${svgLogo.getBBox().width} ${svgLogo.getBBox().height}`);
      }
    }
  } catch (error) {
    console.error(error);
    setTimeout(() => {
      loading.stop();
    }, 1000);
  }
});
